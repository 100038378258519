/**
 * @description Convert the json object to query string.
 * @param {JSON} obj Json object that to be processed.
 * @param {string} separator separating the data using this value
 * @param {string} prefix prefix string of object key.
 * @return {string} query string.
 */
export const objectToQuerystring = (obj, separator = '&', prefix = null, type = 'URL') => {
  return Object.keys(obj).filter((key) => ![null, undefined].includes(obj[key])).reduce((str, key, i) => {
    let delimiter;
    let val;
    delimiter = i === 0 ? '' : separator;
    if (Array.isArray(obj[key])) {
      key = encodeURIComponent(key);
      const arrayVar = obj[key].reduce((innerStr, item) => {
        if (type === 'FORM') {
          val = encodeURIComponent(JSON.stringify(item));
        } else {
          val = encodeURIComponent(item);
        }
        return [innerStr, key, '=', val, '&'].join('');
      }, '');
      return [str, delimiter, arrayVar.trimRightString('&')].join('');
    } else {
      key = encodeURIComponent(key);
      if (type === 'FORM') {
        val = encodeURIComponent(JSON.stringify(obj[key]));
      } else {
        val = encodeURIComponent(obj[key]);
      }
      if (prefix) {
        key = prefix + '.' + key;
      }
      return [str, delimiter, key, '=', decodeURIComponent(val)].join('');
    }
  }, '');
}

/**
 * @description After the validation, values will be converted into FormData if needed.
 * @param {json} apiValues form values.
 * @return {FormData} Modified form values to FormData.
 */
export const convertToFormData = (apiValues) => {
  const formData = new FormData();
  for (const key of Object.keys(apiValues)) {
    let value = apiValues[key];
    if (Array.isArray(value)) {
      value = JSON.stringify(value);
    } else if (value && typeof value === 'object' && Object.keys(value).length) {
      value = JSON.stringify(value);
    }
    formData.append(key, value);
  }
  return formData;
}

export const formValueProcess = (fields = [], form = null) => {
  let formValues = {};
  let isValid = true;
  if (form) {
    let formdata = new FormData(form);
    for (const field of fields) {
      if (field) {
        if (isValid)
          isValid = !(field.error && Object.keys(field.error).length > 0)
        let value = formdata.getAll(field.fieldColumn)
        switch (field.fieldType) {
          case 'textbox':
            if (Array.isArray(value) && value.length > 0)
              if (value.length > 1)
                formValues[field.fieldColumn] = value;
              else
                formValues[field.fieldColumn] = value[0];
            break;
          case 'dropdown':
            if (Array.isArray(value) && value.length > 0)
              if (value.length > 1)
                formValues[field.fieldColumn] = value;
              else
                formValues[field.fieldColumn] = value[0];
            break;


          default:
            break;
        }
      }
    }
  }
  return {
    isValid,
    values: formValues
  };
}

export const queryStringToJson = (query = '') => {
  const searchString = query.split("?")
  const vars = searchString.length > 1 ? searchString[1].split("&") : searchString[0].split("&");
  let query_string = {};
  for (var i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (pair.length === 2) {
      const key = decodeURIComponent(pair[0]);
      const value = decodeURIComponent(pair[1]);
      // If first entry with this name
      if (typeof query_string[key] === "undefined") {
        query_string[key] = decodeURIComponent(value);
        // If second entry with this name
      } else if (typeof query_string[key] === "string") {
        const arr = [query_string[key], decodeURIComponent(value)];
        query_string[key] = arr;
        // If third or later entry with this name
      } else {
        query_string[key].push(decodeURIComponent(value));
      }
    }
  }
  return query_string;
}

export const fullscreenToggle = (elem) => {
  elem = elem || document.documentElement;

  if (!document.fullscreenElement && !document.mozFullScreenElement &&
    !document.webkitFullscreenElement && !document.msFullscreenElement) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }
}