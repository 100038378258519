// import logo from './assets/svg/logo.svg';
import './App.scss';
import Routes from './Routes';
import { AuthContextProvider } from './shared/contexts/auth';
import { GlobalContextProvider } from './shared/contexts/global';

function App() {
  return (
    <AuthContextProvider>
      <GlobalContextProvider>
        <Routes></Routes>
      </GlobalContextProvider>
    </AuthContextProvider>
  );
}

export default App;
