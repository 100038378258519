import React, { Suspense, lazy, useContext } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import AuthContext from './shared/contexts/auth';

const home = lazy(() => import('./modules/home/index'));
const auth = lazy(() => import('./modules/auth/index'));
const conference = lazy(() => import('./modules/conference/index'));
const chatRoom = lazy(() => import('./modules/chat-room/index'));


const Routes = () => {
  const authContext = useContext(AuthContext)
  const isLogin = authContext.isLogin
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path="/">
            {!isLogin && (<Redirect to="/auth" />)}
            {isLogin && (<Redirect to="/home" />)}
          </Route>
          {!isLogin && (<Route path="/auth" component={auth} />)}
          <Route path="/home" component={home} />
          <Route path="/meeting" component={conference} />
          <Route path="/chat" component={chatRoom} />
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  )
};

export default Routes