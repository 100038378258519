import React, { useState } from "react";

export const themes = {
  light: {
    foreground: '#000000',
    background: '#eeeeee',
  },
  dark: {
    foreground: '#ffffff',
    background: '#222222',
  },
};

const GlobalContext = React.createContext({
  theme: themes.dark,
  changeTheme: () => { }
});

export const GlobalContextProvider = (props) => {
  const [theme, setTheme] = useState(localStorage.getItem('theme'))

  const changeTheme = (value) => {
    setTheme(theme[value])
  }

  const initialContextValue = {
    theme,
    changeTheme
  }
  return (
    <GlobalContext.Provider value={initialContextValue}>
      {props.children}
    </GlobalContext.Provider>
  )
};

export default GlobalContext;