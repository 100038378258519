import React, { useEffect, useState } from "react";
import request from "../utils/request";

const AuthContext = React.createContext({
  token: null,
  isLogin: false,
  login: (value) => { },
  logout: () => { },
  currentUser: null
});

export const AuthContextProvider = (props) => {
  const [token, setToken] = useState(localStorage.getItem('token'))
  const [currentUser, setCurrentUser] = useState(null)

  const isLogin = !!token;

  const getCurrentUser = async () => {
    if (token) {
      // const resp = await request('todos')
      setCurrentUser(token);
    }
  }

  useEffect(() => {
    getCurrentUser();
  }, [])

  const loginHandler = (value, userInfo) => {
    if (value) {
      localStorage.setItem('token', value)
      setToken(value)
      setCurrentUser(userInfo)
    }
  }

  const logoutHandler = () => {
    localStorage.removeItem('token')
    setToken(null)
  }

  const initialContextValue = {
    token,
    isLogin,
    login: loginHandler,
    logout: logoutHandler,
    currentUser
  }
  return (
    <>
      {(isLogin && !currentUser) && (
        <div>Initial Loading...</div>
      )}
      {(!isLogin || (isLogin && currentUser)) && (
        <AuthContext.Provider value={initialContextValue}>
          {props.children}
        </AuthContext.Provider>
      )}
    </>
  )
}
export default AuthContext;